import {
	AppBar,
	Button,
	Container,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	makeStyles,
	MenuItem,
	Paper,
	Select,
	Switch,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import TwitterIcon from '@material-ui/icons/Twitter';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import axios from 'axios';
import React from 'react';
import SnackBarWrapper from '../components/SnackBarWrapper';
import { connect } from 'react-redux';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import { setThemeDark, setThemeLight } from '../store/actions';

const useStyles = makeStyles(theme => ({
	container: {
		minHeight: '100vh',
		minWidth: '40vw',
	},
	heading: {
		fontWeight: 300,
		color: theme.palette.primary,
	},
	tool: {
		display: 'flex',
		justifyContent: 'center',
	},
	content: {
		marginTop: '10vh',
		[theme.breakpoints.up('sm')]: {
			maxWidth: '60vh',
		},
	},
	subHeading: {
		fontWeight: 300,
		marginBottom: '20px',
	},
	subtext: {
		color: theme.palette.primary.lightText,
	},
	contenttwo: {
		marginTop: '3vh',
		[theme.breakpoints.up('sm')]: {
			maxWidth: '60vh',
		},
	},
	option: {
		fontWeight: 300,
	},
	formControl: {
		minWidth: 120,
		marginBottom: 10,
	},
	buttonContainer: {
		marginTop: '2vh',
		cursor: 'pointer',
	},
	icon: {
		margin: '4px',
	},
}));

function Changes(props) {
	const classes = useStyles();
	const [state, setState] = React.useState({
		email: '',
		age: '',
		state: '',
		pincode: '',
		district: '',
		states: [],
		districts: [],
		districtDisabled: true,
		option: false,
		choice: 'Request Unsubscription',
		visibleSnackBar: false,
		snackbarMessage: '',
		snackbarType: '',
		autoHideDuration: 2000,
	});
	const { dispatch } = props;
	const handleChange = () => {
		setState(state => ({
			...state,
			option: !state.option,
			state: '',
			pincode: '',
			district: '',
		}));
	};
	const [stn, SetStn] = React.useState('Request Unsubscription');
	const setLight = () => {
		dispatch(setThemeLight());
		localStorage.setItem('themeType', 'light');
	};
	const setDark = () => {
		dispatch(setThemeDark());
		localStorage.setItem('themeType', 'dark');
	};

	React.useEffect(() => {
		const fetchStates = async () => {
			const data = await axios.get(
				'https://cdn-api.co-vin.in/api/v2/admin/location/states',
			);
			setState(state => ({
				...state,
				states: data.data.states,
			}));
		};
		fetchStates();
	}, [props]);
	const handleDistrictChange = e => {
		setState(state => ({
			...state,
			district: e.target.value,
		}));
	};
	const handleAgeChange = e => {
		setState(state => ({
			...state,
			age: e.target.value,
		}));
	};
	const handleStateChange = async e => {
		setState(state => ({
			...state,
			state: e.target.value,
		}));
		const data = await await axios.get(
			'https://cdn-api.co-vin.in/api/v2/admin/location/districts/' +
				e.target.value,
		);
		setState(state => ({
			...state,
			districts: data.data.districts,
			districtDisabled: false,
		}));
	};
	function validateEmail(email) {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	function CheckIndianZipCode(b) {
		const re = /^[1-9][0-9]{5}$/;
		return re.test(String(b));
	}
	const submit = async e => {
		const email = state.email;
		if (email.trim().length === 0 || !validateEmail(email)) {
			setState({
				...state,
				visibleSnackBar: true,
				snackbarMessage: 'Please Enter a valid email.',
				snackbarType: 'error',
			});
			return;
		}
		const age = state.age;
		if (age === '') {
			setState({
				...state,
				visibleSnackBar: true,
				snackbarMessage: 'Please Select an Age Group.',
				snackbarType: 'error',
			});
			return;
		}
		let pincode, state_id, district;
		if (state.option) {
			if (state.pincode === '' || !CheckIndianZipCode(state.pincode)) {
				setState({
					...state,
					visibleSnackBar: true,
					snackbarMessage: 'Please Enter a valid pincode.',
					snackbarType: 'error',
				});
				return;
			}
			pincode = state.pincode;
		} else {
			if (state.state === '' || state.district === '') {
				setState({
					...state,
					visibleSnackBar: true,
					snackbarMessage: 'Please Select a State/District.',
					snackbarType: 'error',
				});
				return;
			}
			state_id = state.state;
			district = state.district;
		}
		const data = {
			email: email,
			pincode: pincode || -1,
			state: state_id || -1,
			district: district || -1,
			age: age,
		};

		const response = await axios.post('/api/reregister', data);

		setState({
			...state,
			visibleSnackBar: true,
			snackbarMessage: response.data.msg,
			snackbarType: response.data.success ? 'success' : 'error',
		});
	};
	const unsub = async e => {
		e.preventDefault();
		const email = state.email;
		if (!validateEmail(email)) {
			setState({
				...state,
				visibleSnackBar: true,
				snackbarMessage: 'Please Enter a valid email.',
				snackbarType: 'error',
			});
			return;
		}
		const data = {
			email: email,
		};
		const response = await axios.post('/api/emailunsubscribe', data);

		setState({
			...state,
			visibleSnackBar: true,
			snackbarMessage: response.data.msg,
			snackbarType: response.data.success ? 'success' : 'error',
		});
	};
	const settings = ['Request Unsubscription', 'Change Location'];
	const changeSetting = e => {
		setState(state => ({
			...state,
			choice: e.target.value,
		}));
		SetStn(e.target.value);
	};
	const closeSnackBar = () => {
		setState({ ...state, visibleSnackBar: false });
	};
	const switchbar = i => {
		let j = -1;
		for (let x = 0; x < settings.length; x = x + 1) {
			if (settings[x] === i) j = x;
		}
		switch (j) {
			case 0: {
				return (
					<>
						<TextField
							required
							id="email"
							label="Email"
							fullWidth
							variant="outlined"
							margin="dense"
							onChange={e => {
								setState(state => ({
									...state,
									email: e.target.value,
								}));
							}}
						/>
						<Grid
							align="center"
							className={classes.buttonContainer}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={unsub}
							>
								Submit
							</Button>
						</Grid>
					</>
				);
			}
			case 1: {
				return (
					<>
						<TextField
							required
							id="email"
							label="Email"
							fullWidth
							variant="outlined"
							margin="dense"
							onChange={e => {
								setState(state => ({
									...state,
									email: e.target.value,
								}));
							}}
						/>
						<FormControl
							variant="outlined"
							className={classes.formControl}
							margin="dense"
							fullWidth
							required
						>
							<InputLabel id="age">Age Group</InputLabel>
							<Select
								labelId="age"
								id="age"
								value={state.age}
								onChange={handleAgeChange}
								label="Age Group"
								required
							>
								<MenuItem key={18} value={18}>
									18-45
								</MenuItem>
								<MenuItem key={45} value={45}>
									45+
								</MenuItem>
							</Select>
						</FormControl>
						<Typography
							component="div"
							style={{
								marginTop: '10px',
							}}
						>
							<Grid
								component="label"
								container
								display="flex"
								justify="center"
								spacing={1}
							>
								<Grid item>
									<Typography
										variant={'h6'}
										className={classes.option}
									>
										District
									</Typography>
								</Grid>
								<Grid item>
									<Switch
										name="selection"
										color="secondary"
										checked={state.option}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item>
									<Typography
										variant={'h6'}
										className={classes.option}
									>
										Pincode
									</Typography>
								</Grid>
							</Grid>
						</Typography>
						<Typography
							component="div"
							style={{
								marginTop: '10px',
							}}
						>
							{!state.option === true ? (
								<>
									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
										fullWidth
									>
										<InputLabel id="demo-simple-select-outlined-label">
											State
										</InputLabel>
										<Select
											labelId="demo-simple-select-outlined-label"
											id="demo-simple-select-outlined"
											value={state.state}
											onChange={handleStateChange}
											label="State"
											required
										>
											{state.states.map(item => {
												return (
													<MenuItem
														key={item.state_id}
														value={item.state_id}
													>
														{item.state_name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
									<FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
										fullWidth
									>
										<InputLabel id="district">
											District
										</InputLabel>
										<Select
											labelId="district-label"
											id="district"
											value={state.district}
											onChange={handleDistrictChange}
											label="District"
											required
											disabled={state.districtDisabled}
										>
											{state.districts.map(item => {
												return (
													<MenuItem
														key={item.district_id}
														value={item.district_id}
													>
														{item.district_name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</>
							) : (
								<>
									<TextField
										required
										id="pincode"
										label="Pincode"
										fullWidth
										variant="outlined"
										margin="dense"
										onChange={e => {
											setState(state => ({
												...state,
												pincode: e.target.value,
											}));
										}}
									/>
									<FormHelperText>
										Register with state/district to get a
										greater coverage of centers.
									</FormHelperText>
								</>
							)}
						</Typography>
						<Grid
							align="center"
							className={classes.buttonContainer}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={submit}
							>
								Submit
							</Button>
						</Grid>
					</>
				);
			}
			default: {
				return <div>NOPE</div>;
			}
		}
	};
	return (
		<>
			<Grid container direction="column" className={classes.container}>
				<Grid item>
					<AppBar color="primary">
						<Toolbar className={classes.tool}>
							<Typography
								variant={'h4'}
								className={classes.heading}
								align="center"
							>
								GET MY VACCINE
							</Typography>
						</Toolbar>
					</AppBar>
				</Grid>
				<Grid item>
					<Container className={classes.content}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							<Typography
								variant={'h5'}
								className={classes.subHeading}
							>
								Settings
							</Typography>
							<div
								style={{
									marginLeft: 'auto',
								}}
							>
								<Tooltip title="Toggle Mode">
									{props.themeData.themeType === 'light' ? (
										<Brightness4Icon
											className={classes.icon}
											onClick={setDark}
										/>
									) : (
										<Brightness7Icon
											className={classes.icon}
											onClick={setLight}
										/>
									)}
								</Tooltip>
								<Tooltip title="Home">
									<HomeIcon
										className={classes.icon}
										onClick={() => props.history.push('/')}
									/>
								</Tooltip>
							</div>
						</div>
					</Container>
				</Grid>

				<Container fluid className={classes.contenttwo}>
					<TextField
						id="outlined-select-settings"
						select
						value={stn}
						onChange={changeSetting}
						label="Setting"
						helperText="Please select setting to be changed."
						variant="outlined"
						fullWidth
						margin="dense"
					>
						<MenuItem value={'Request Unsubscription'}>
							Request Unsubscription
						</MenuItem>
						<MenuItem value={'Change Location'}>
							Change Location
						</MenuItem>
					</TextField>

					<Paper elevation={1} style={{ padding: '16px' }}>
						{switchbar(stn)}
					</Paper>
				</Container>
				<AppBar
					position="static"
					style={{
						marginTop: 'auto',
					}}
				>
					<Container maxWidth="md">
						<Toolbar>
							<Typography variant="div" color="inherit">
								© 2021 Arpan Nagar
							</Typography>
							<Grid
								item
								style={{
									marginLeft: 'auto',
								}}
							>
								<Tooltip title="Toggle Mode">
									{props.themeData.themeType === 'light' ? (
										<Brightness4Icon
											className={classes.icon}
											onClick={setDark}
										/>
									) : (
										<Brightness7Icon
											className={classes.icon}
											onClick={setLight}
										/>
									)}
								</Tooltip>
								<Tooltip title="Twitter">
									<TwitterIcon
										className={classes.icon}
										onClick={() =>
											window.open(
												'https://twitter.com/arpunagar',
											)
										}
									/>
								</Tooltip>
								<Tooltip title="GitHub">
									<GitHubIcon
										className={classes.icon}
										onClick={() =>
											window.open(
												'https://github.com/arpu-nagar',
											)
										}
									/>
								</Tooltip>
								<Tooltip title="Linkedin">
									<LinkedInIcon
										className={classes.icon}
										onClick={() =>
											window.open(
												'https://www.linkedin.com/in/arpan-nagar-231815152/',
											)
										}
									/>
								</Tooltip>
							</Grid>
						</Toolbar>
					</Container>
				</AppBar>
				<SnackBarWrapper
					open={state.visibleSnackBar}
					onClose={closeSnackBar}
					message={state.snackbarMessage}
					type={state.snackbarType}
					autoHideDuration={state.autoHideDuration}
				/>
			</Grid>
		</>
	);
}
const mapStateToProps = state => {
	return {
		themeData: state.ThemeReducer,
	};
};
export default connect(mapStateToProps)(Changes);
