import { SET_THEME_DARK_MODE, SET_THEME_LIGHT_MODE } from "../constants";

export function setThemeLight(payload)
{
    return {
        type: SET_THEME_LIGHT_MODE,
        payload
    }
}


export function setThemeDark(payload)
{
    return {
        type: SET_THEME_DARK_MODE,
        payload
    }
}