import { SET_THEME_DARK_MODE, SET_THEME_LIGHT_MODE } from './../constants';

const initialState = {
	themeType: 'dark',
};

export default function ThemeReducer(state = initialState, action) {
	switch (action.type) {
		case SET_THEME_DARK_MODE:
			return { ...state, themeType: 'dark' };
		case SET_THEME_LIGHT_MODE:
			return { ...state, themeType: 'light' };
		default:
			return state;
	}
}
