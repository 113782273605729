import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import Main from './pages/Main';
import { connect } from 'react-redux';
import { green } from '@material-ui/core/colors';
import Error404 from './pages/Error404';
import { setThemeDark, setThemeLight } from './store/actions';
import Changes from './pages/Changes';

function initializeReactGA(code) {
	ReactGA.initialize(code);
}

function App(props) {
	let themeType = localStorage.getItem('themeType');
	if (themeType === null) {
		themeType = 'light';
		props.dispatch(setThemeLight());
		localStorage.setItem('themeType', 'light');
	} else if (
		themeType === 'light' &&
		props.themeData.themeType !== themeType
	) {
		props.dispatch(setThemeLight());
	} else if (
		themeType === 'dark' &&
		props.themeData.themeType !== themeType
	) {
		props.dispatch(setThemeDark());
	}
	initializeReactGA('G-T060HJ648W');
	const theme = createMuiTheme({
		palette: {
			type: themeType,
			primary: {
				light: themeType === 'light' ? '#303d70' : '#214c82',
				main: themeType === 'light' ? '#303d70' : '#214c82',
				dark: themeType === 'light' ? '#303d70' : '#112845',
				// contrastText: "#fff",
				lightText: '#646468',
				logoColor: '#3399dd',
				offWhite: themeType === 'light' ? '#e3e3e5' : '#004383',
				comment: '#a9a9a9', //dark grey
				actionText: themeType === 'light' ? '#263238c9' : '#ffffff',
				actionCount: '#e67777',
				recommendationsBorder: '#b4b2b2',
				snackbar: green[600],
				addToDataStory: '#cecece',
				tabs: themeType === 'light' ? '#1f3970' : '#214c82',
				iconLight: '#b0bec5',
			},
			secondary: {
				light: '#20314cc2',
				main: themeType === 'light' ? '#20314c' : '#ffffff',
				dark: '#162338',
				contrastText: '#000',
			},
			background: {
				black: '#000',
			},
		},
		padding: {
			datastory: 16, //theme.spacing(2) can also be used which is = 8 * 2,
			horizontalScroll: '20px',
			shareDialogBox: 15,
		},
		margin: {
			addTextDialog: '10px',
			shareDialogBox: '10px',
		},
	});
	return (
		<>
			<Router>
				<MuiThemeProvider theme={theme}>
					<CssBaseline />
					<Switch>
						<Route
							path="/settings"
							component={props => <Changes {...props} />}
						/>
						<Route
							path="/"
							component={props => <Main {...props} />}
						/>

						<Route component={Error404} />
					</Switch>
				</MuiThemeProvider>
			</Router>
		</>
	);
}
const mapStateToProps = state => {
	return {
		themeData: state.ThemeReducer,
	};
};
export default connect(mapStateToProps)(App);
